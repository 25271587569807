import React from "react";

import logo from './logo.png';
import smb001 from './images/zeroboi.png';
import pixelSpray from './images/pixel-spray.png';

import twitter from './images/icons/twitter.png';
import telegram from './images/icons/telegram.png';

import meme1 from './images/redcandleszerooo.png';
import meme2 from './images/zeroboi101.png';
import meme3 from './images/zeroalliknow.png';
import meme4 from './images/hebought0.png';
import meme5 from './images/zerotozero.png';
import meme6 from './images/getinzero.png';
import meme7 from './images/zerohub.png';

import meme8 from './images/itsgoing0.png';
import meme9 from './images/middle0.png';
import meme10 from './images/rotate0.png';
import meme11 from './images/navyzerobois.jpg';
import meme12 from './images/bonkmoonboi.png';
import meme13 from './images/zerobois4e.jpg';
import meme14 from './images/cult0.png';

import meme15 from './images/fkmoonboi.png';
import meme16 from './images/zeroboinow.png';
import meme17 from './images/dumphah.png';
import meme18 from './images/navyzerobois.jpg';
import meme19 from './images/weird101.jpg';
import meme20 from './images/team0.jpg';
import meme21 from './images/ansemboi.png';

import './App.css';

import './fonts/pixel/Uni0553.woff';
import './fonts/pixel/Uni0553.woff2';
import './fonts/pixel/Uni0553.ttf';

import './styles/theme.css'
import './styles/mobile.css'


function App() {

  const div1 = React.useRef()
  const div2 = React.useRef()
  const div3 = React.useRef()
  const div4 = React.useRef()

  /**
   * @param {HTMLElement} target 
   */
  function smoothScroll(target) {
    const { top } = target.getBoundingClientRect()
    window.scrollTo({
      top: top + window.pageYOffset,
      behavior: "smooth"
    });
  }

  return (
    <div className="App">
      <nav className="nav">
        <div>
          <img src={logo} className="logo" alt="logo"
            onClick={() => smoothScroll(div1.current)} />

          <input className="nav-btn" type="checkbox" id="menu-btn" />
          <label className="nav-icon" for="menu-btn"><span className="navicon"></span></label>
          <div className='nav-items'>
            <span className="button"
              onClick={() => smoothScroll(div1.current)}>
              zerobois</span>
            <span className="button"
              onClick={() => smoothScroll(div2.current)}>
              contract</span>
            <span className="button"
              onClick={() => smoothScroll(div3.current)}>
              memes</span>
            <span><a target={'_blank'} href='https://jup.ag/swap/SOL-ZEROOO_FvuAj8QKGofsfULiMjuYGj7dsVFQqvMxirpoWYpBWwEw'>BUY $ZEROOO</a></span>
            <span><a target={'_blank'} href='https://birdeye.so/token/FvuAj8QKGofsfULiMjuYGj7dsVFQqvMxirpoWYpBWwEw?chain=solana'>birdeye</a></span>
            <span><a target={'_blank'} href='https://dexscreener.com/solana/3takn4zfwfmcd1bebweuzhbgkujfa5gaup2mpx1rfdsf'>dexsc</a></span>
          </div>
        </div>
        <div className="socials">
          <a target={'_blank'} href='https://twitter.com/Zer0Bois'><img src={twitter} /></a>
          <a target={'_blank'} href='https://t.me/zeroboisSOL'><img src={telegram} /></a>
        </div>
      </nav>


      <div className="intro" ref={div1}>
        <div className="container">
          <marquee behavior="scroll" direction="right" scrollamount="30"> I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO  </marquee>
          <div className="image-wrapper">
            <img src={smb001} className="big-smb" />
            <div className="image-box">
              <span>ZERO SOON</span>
            </div>
          </div>
          <div className="content-wrapper">
            <h1>NAVY SEALS OF CRYPTO</h1>
            <h3>ZEROBOIS ARE THE NAVY SEALS OF CRYPTO</h3>
            <p>WE DO NOT CRY AS WE GO TO ZERO</p>
            <p> WE STFU UP AS OUR WALLETS GO NIL</p>
            <p>WE LAUGH AS RED CANDLES STICK OUR ASS</p>
            <p> WE STARE GREEN CANDLES IN THE FACE </p>
            <p> WITHOUT BLINK, WITHOUT FEAR </p>
            <p> WITHOUT LOOKING AWAY, IN THE FACE IN THE EYES </p>
            <p> WE FEAR NO RED </p>
            <marquee direction="left" scrollamount="50"> I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO I AM A ZEROBOI. I STFU N HOLD TO ZERO  </marquee>
          </div>
        </div>
      </div>

      <div className="roadmap" ref={div2}>
        <div className="container">
          <h2>Contract</h2>
          <div className="content-wrapper">
            <h2>FvuAj8QKGofsfULiMjuYGj7dsVFQqvMxirpoWYpBWwEw</h2>
          </div>
        </div>
      </div>
      <div className="memes" ref={div3}>
        <div className="pixel-spray"><img src={pixelSpray} /></div>
        <div className="container">
          <div className="content-wrapper">
            <div class="marquee">
              <div class="track">
                <div class="content">
                  <img className="meme" src={meme4} />
                  <img className="meme" src={meme2} />
                  <img className="meme" src={meme3} />
                  <img className="meme" src={meme1} />
                  <img className="meme" src={meme7} />
                  <img className="meme" src={meme5} />
                  <img className="meme" src={meme6} />
                </div>
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <div class="marquee2">
              <div class="track">
                <div class="content">
                  <img className="meme" src={meme9} />
                  <img className="meme" src={meme8} />
                  <img className="meme" src={meme10} />
                  <img className="meme" src={meme11} />
                  <img className="meme" src={meme13} />
                  <img className="meme" src={meme14} />
                  <img className="meme" src={meme12} />
                </div>
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <div class="marquee3">
              <div class="track">
                <div class="content">
                  <img className="meme" src={meme19} />
                  <img className="meme" src={meme17} />
                  <img className="meme" src={meme16} />
                  <img className="meme" src={meme15} />
                  <img className="meme" src={meme20} />
                  <img className="meme" src={meme21} />
                  <img className="meme" src={meme21} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <footer>
        <img src={logo} className="logo" alt="logo"
          onClick={() => smoothScroll(div1.current)} />
        <div className="socials">
          <a target={'_blank'} href='https://twitter.com/Zer0Bois'><img src={twitter} /></a>
          <a target={'_blank'} href='https://t.me/zeroboisSOL'><img src={telegram} /></a>
        </div>
        <strong>I AM A ZEROBOI. I STFU N HOLD TO ZERO</strong>
      </footer>
    </div>

  );
}


export default App;